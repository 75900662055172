/** @jsx jsx */
import { Footer as ThemeFooter, Styled, Flex, useColorMode, jsx } from "theme-ui"

const Footer = () => {
    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`
    const toggleColorMode = (e: any) => {
        setColorMode(isDark ? `light` : `dark`)
    }

    return (
        <ThemeFooter>
            <button
                sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
                onClick={toggleColorMode}
                type="button"
                aria-label="Toggle dark mode"
            >
                {isDark ? `Light` : `Dark`}
            </button>
            Copyright &copy; 2016 - {new Date().getFullYear()}. All rights reserved.
        </ThemeFooter>
    )
}

export default Footer
